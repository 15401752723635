import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Article } from '../types';


@Component({
  selector: 'lib-md-call',
  templateUrl: './md-call.component.html',
  styleUrls: ['../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdCallComponent {
  @Input() article$: Observable<Article> = of();
  @Input() language: string = 'de';
  @Input() img: boolean = false;


  constructor() { }


}
