import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Article } from '../types';

@Component({
  selector: 'lib-md-video',
  templateUrl: './md-video.component.html',
  styleUrls: ['../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdVideoComponent implements OnInit {
  private apiLoaded = false;

  @Input() videoId: string = 'qzc7vY9VTnk';
  @Input() language: string = 'de';
  @Input() article$: Observable<Article> = of();
  @Input() hdr: boolean = true;
  @Input() ftr: boolean = true;
  @Input() columns: number = 1;

  constructor() { }

  ngOnInit(): void {
    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
      this.breakPoints();
    }
  }

  breakPoints() {
    switch (true) {
      case (window.innerWidth <= 640):
        this.columns = 1;
        break;
      case (window.innerWidth <= 1280):
        this.columns = 2;
        break;
      case (window.innerWidth <= 1920):
        this.columns = 3;
        break;
      default:
        this.columns = 4;
    }
  }
  
  onResize(event: any) {
    this.breakPoints();
    console.log(event);
  }


}
