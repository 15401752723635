import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { MdFormComponent } from './md-form.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { PipeModule } from '../../pipe/pipe.module';

const modules = [
  CommonModule,
  RouterModule,
  ReactiveFormsModule,
  PipeModule,
  MatButtonModule,
  MatCardModule,
  MatIconModule,
  MatInputModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatDividerModule,
]
@NgModule({
  declarations: [
    MdFormComponent
  ],
  imports: [
    ...modules
  ],
  exports: [
    MdFormComponent,
  ]
})
export class MdFormModule { }
