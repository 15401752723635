import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MdCallComponent } from './md-call.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';

import { PipeModule } from '../../pipe/pipe.module';


const modules = [
  CommonModule,
  RouterModule,
  PipeModule,
  MatButtonModule,
  MatCardModule,
  MatDividerModule,
]
@NgModule({
  declarations: [
    MdCallComponent,
  ],
  imports: [
    ...modules
  ],
  exports: [
    MdCallComponent,
  ]
})
export class MdCallModule { }
