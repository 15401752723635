import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YouTubePlayerModule } from '@angular/youtube-player';

import { MdVideoComponent } from './md-video.component';
import { MatGridListModule } from '@angular/material/grid-list';

const modules = [
  CommonModule,
  YouTubePlayerModule,
  MatGridListModule,
]
@NgModule({
  declarations: [
    MdVideoComponent,
  ],
  imports: [
    ...modules
  ],
  exports: [
    MdVideoComponent,
  ]
})
export class MdVideoModule { }
