import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// import { ClipboardModule } from '@angular/cdk/clipboard';

// import { MdEditComponent } from './md-edit.component';

import { MatButtonModule } from '@angular/material/button';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MdEditComponent } from './md-edit.component';
import { PipeModule } from '../../pipe/pipe.module';


@NgModule({
  declarations: [
    MdEditComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    // ClipboardModule,
    PipeModule,
    MatButtonModule,
    // MatSnackBarModule,
    MatTooltipModule,
    MatDialogModule,
    MatCardModule,
  ],
  exports: [
    MdEditComponent,
  ]
})
export class MdEditModule { }
