<!-- <p>### md-call works!</p> -->

@if(article$ | async; as article;){

<mat-card appearance="outlined">

    <mat-card-header style="max-width: 30rem;">
        <mat-card-title>{{article.title}}</mat-card-title>
        <mat-card-subtitle>{{article.subtitle}}</mat-card-subtitle>
    </mat-card-header>

    @if(img){
    <img mat-card-image [src]="[article?.image | image | async]" [alt]="article?.title">
    }

    <mat-card-content style="padding: 1rem 0 0;">
        <!-- <div [innerHTML]="article.text"></div> -->
    </mat-card-content>

    <mat-divider></mat-divider>

    <mat-card-actions>
        <button mat-button color="primary" [routerLink]="['/', language, 'contact']" aria-label="Contact">{{article?.label1}}</button>
    </mat-card-actions>

</mat-card>


}