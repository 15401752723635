import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MdFooterComponent } from './md-footer.component';

import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
// import { MatCardModule } from '@angular/material/card';

const modules = [
  CommonModule,
  RouterModule,
  MatDividerModule,
  MatListModule,
  // MatCardModule,
]
@NgModule({
  declarations: [
    MdFooterComponent,
  ],
  imports: [
    ...modules
  ],
  exports: [
    MdFooterComponent,
  ]
})
export class MdFooterModule { }
