import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MdBrickModule } from './md-brick/md-brick.module';
import { MdCardModule } from './md-card/md-card.module';
import { MdCalModule } from './md-cal/md-cal.module';
import { MdCallModule } from './md-call/md-call.module';
import { MdFooterModule } from './md-footer/md-footer.module';
import { MdFormModule } from './md-form/md-form.module';
import { MdHeroModule } from './md-hero/md-hero.module';
import { MdJobsModule } from './md-jobs/md-jobs.module';
import { MdPageModule } from './md-page/md-page.module';
import { MdPhotoModule } from './md-photo/md-photo.module';
import { MdVideoModule } from './md-video/md-video.module';
import { MdMapsModule } from './md-maps/md-maps.module';
import { MdCartModule } from './md-cart/md-cart.module';
import { MdOrderModule } from './md-order/md-order.module';
import { MdNaviModule } from './md-navi/md-navi.module';

import { MdBrickComponent } from './md-brick/md-brick.component';
import { MdCalComponent } from './md-cal/md-cal.component';
import { MdCardComponent } from './md-card/md-card.component';
import { MdCartComponent } from './md-cart/md-cart.component';
import { MdFooterComponent } from './md-footer/md-footer.component';
import { MdFormComponent } from './md-form/md-form.component';
import { MdHeroComponent } from './md-hero/md-hero.component';
import { MdJobsComponent } from './md-jobs/md-jobs.component';
import { MdMapsComponent } from './md-maps/md-maps.component';
import { MdOrderComponent } from './md-order/md-order.component';
import { MdPageComponent } from './md-page/md-page.component';
import { MdPhotoComponent } from './md-photo/md-photo.component';
import { MdVideoComponent } from './md-video/md-video.component';
import { MdCallComponent } from './md-call/md-call.component';
import { MdNaviComponent } from './md-navi/md-navi.component';



@NgModule({
  imports: [
    CommonModule,
    MdBrickModule,
    MdCalModule,
    MdCallModule,
    MdCardModule,
    MdCartModule,
    MdFooterModule,
    MdFormModule,
    MdHeroModule,
    MdJobsModule,
    MdMapsModule,
    MdOrderModule,
    MdPageModule,
    MdPhotoModule,
    MdVideoModule,
    MdNaviModule,
  ],
  exports: [
    MdBrickComponent,
    MdCardComponent,
    MdCartComponent,
    MdCalComponent,
    MdCallComponent,
    MdFooterComponent,
    MdFormComponent,
    MdHeroComponent,
    MdJobsComponent,
    MdMapsComponent,
    MdOrderComponent,
    MdPageComponent,
    MdPhotoComponent,
    MdVideoComponent,
    MdNaviComponent,
  ],
  declarations: [
  ],
})
export class MdcFoModule { }
