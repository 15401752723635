import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Navigation } from '../types';


@Component({
  selector: 'lib-md-footer',
  templateUrl: './md-footer.component.html',
  styleUrls: ['../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdFooterComponent {
  @Input() list$: Observable<Navigation> = of();
  @Input() language: string = 'de';


  constructor() { }

}
