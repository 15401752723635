import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {ClipboardModule} from '@angular/cdk/clipboard';

import { MdLightComponent } from './md-light.component';

import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatDialogClose } from '@angular/material/dialog';
import { PipeModule } from '../../pipe/pipe.module';


@NgModule({
  declarations: [
    MdLightComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ClipboardModule,
    PipeModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatCardModule,
    MatDialogClose,
  ],
  exports: [
    MdLightComponent,
  ]
})
export class MdLightModule { }
