import { Component, inject } from '@angular/core';
import { Storage } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { listAll, ref } from 'firebase/storage';
// import { Observable, of } from 'rxjs';



@Component({
  selector: 'lib-md-light',
  templateUrl: './md-light.component.html',
  styleUrls: ['../style.scss']
})
export class MdLightComponent {
  private storage: Storage = inject(Storage);
  // files$: Observable<string> = of();
  files: string[] = [];


  constructor(
    private _snackBar: MatSnackBar,
  ) {
    const path = 'images/';
    const refer = ref(this.storage, path);
    // console.log('ref', refer);

    listAll(refer)
      .then((res) => {
        // res.prefixes.forEach((folderRef) => {
        //   console.log('prefix', folderRef);
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
        // });
        res.items.forEach((itemRef) => {
          // console.log('item', itemRef);
          this.files.push(itemRef.fullPath);
          // All the items under listRef.
        });
      }).catch((error) => {
        console.log('err', error);
        // Uh-oh, an error occurred!
      });

    // refer.listAll()
    // this.files$ = from(getDownloadURL(refer));
    // this.files$.subscribe(x => console.log(x));
  }

  openSnackBar(message: string = 'action', action: string = 'undo') {
    this._snackBar.open(message, action, { duration: 5000 });
    console.warn("snack");
  }


}
