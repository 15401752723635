<!-- <p>### md-quota works!</p> -->


<!-- {{content$ | async | json}} -->

<div class="grid2">

  @if(quota$ | async; as quota){
  @for(item of quota; track item){

  <mat-card appearance="outlined">
    <!-- <mat-card-header> -->
    <!-- <div mat-card-avatar class="example-header-image"></div> -->
    <!-- <mat-card-title>{{item?.firstName}} {{item?.lastName}}</mat-card-title>
        <mat-card-subtitle>{{item?.email}}</mat-card-subtitle> -->
    <!-- </mat-card-header> -->
    <!-- <img mat-card-image src="" alt="Photo of a Shiba Inu"> -->
    <mat-card-header>
      <mat-card-title>{{item?.firstName}} {{item?.lastName}}</mat-card-title>
      <mat-card-subtitle>{{item?.email}}</mat-card-subtitle>
      <mat-card-subtitle>
        <a mat-button href="tel:{{item?.phone}}" style="color: bisque;">
          <span>{{item?.phone}}</span>
        </a>
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <mat-list role="list">
        <mat-list-item role="listitem">{{item?.comment}}</mat-list-item>
        <mat-list-item role="listitem">{{item?.status}}</mat-list-item>
      </mat-list>
    </mat-card-content>

    <mat-card-actions>
      <button mat-button color="primary">LIKE</button>
      <button mat-button color="accent">SHARE</button>
    </mat-card-actions>
  </mat-card>
  }
  }

</div>