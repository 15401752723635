import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';

import { MdMapsComponent } from './md-maps.component';

const modules = [
  CommonModule,
  GoogleMapsModule,
  // HttpClientModule,
  // HttpClientJsonpModule,
]
@NgModule({
  declarations: [
    MdMapsComponent,
  ],
  imports: [
    ...modules
  ],
  exports: [
    MdMapsComponent,
  ]
})
export class MdMapsModule { }
