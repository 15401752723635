import { Pipe, PipeTransform, inject } from '@angular/core';
import { Observable, from } from 'rxjs';

import { Storage, getDownloadURL, ref } from '@angular/fire/storage';
import { keepUnstableUntilFirst } from '@angular/fire';
import { traceUntilFirst } from '@angular/fire/performance';

// const TRANSPARENT_PNG = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

@Pipe({
  name: 'image', 
  pure: true
})
export class ImagePipe implements PipeTransform {
  private storage: Storage = inject(Storage);

  constructor(  ) { }

  transform(value: string | undefined, path: string = ""): Observable<string> {
    if (value) {
      const refer = ref(this.storage, path + value);
      return from(getDownloadURL(refer))
        .pipe(
          keepUnstableUntilFirst,
          traceUntilFirst('storage'),
          // startWith(TRANSPARENT_PNG),
        );
    } else {
      return from(getDownloadURL(ref(this.storage, '/images/default.jpg')));
    }
    // return of('none');
  }
}

