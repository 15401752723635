<!-- <p>md-edit works!</p> -->

<mat-card>
    <mat-card-header>
        <mat-card-title>Title</mat-card-title>
        <mat-card-subtitle>SubTitle</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae maiores voluptatem cum praesentium adipisci, dolores magni delectus rerum. Inventore magni quis mollitia possimus, atque culpa autem omnis nihil alias sed. </p>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button (click)="openDialog()" matTooltip="Info about the action" aria-label="Dialog">Open dialog</button>
    </mat-card-actions>
</mat-card>