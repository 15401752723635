import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Article } from '../types';


@Component({
  selector: 'lib-md-jobs',
  templateUrl: './md-jobs.component.html',
  styleUrls: ['../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdJobsComponent {
  @Input() article$: Observable<Article> = of();
  @Input() language: string = 'de';
  @Input() btn: boolean = true;
  @Input() img: boolean = true;
  @Input() crd: boolean = true;

  txt: boolean = false;


  constructor() { }

  // showText() {
  //   this.txt = !this.txt;
  // }

}
