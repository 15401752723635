import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef,
  MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose,
} from '@angular/material/dialog';
import { MdLightModule } from '../md-light/md-light.module';


@Component({
  selector: 'lib-md-edit',
  templateUrl: './md-edit.component.html',
  styleUrls: ['../style.scss']
})
export class MdEditComponent {

  constructor(
    public dialog: MatDialog,
  ) { }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(DialogComponent,
      {
        // maxWidth: '80vw',
        data: { name: 'test name' },
        hasBackdrop: true
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
      // this.res = result;
    });
  }

}

@Component({
  selector: 'lib-dialog-content-example-dialog',
  template: `
    <h1 mat-dialog-title>Dialog with elements - {{data.name}}</h1>
    <mat-dialog-content>
      <!-- This dialog showcases the title, close, content and actions elements. -->
    <lib-md-light></lib-md-light>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button color="primary" mat-dialog-close>Close</button>
      <button mat-button color="accent" (click)="onNoClick()">No Thanks</button>
    </mat-dialog-actions>
  `,
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    MdLightModule,
  ],
  // imports: [MatDialogModule, MatButtonModule],
})
export class DialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: '/images' }
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
