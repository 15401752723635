<!-- <p>### md-form works!</p> -->

@if(contact$ | async; as item){
<div style="display: flex; justify-content: center; ">

    <div style="flex: 1 100%; max-width: 30rem;">
        <mat-card appearance="outlined">

            <mat-card-header style="padding-bottom: 2rem;">
                <mat-card-title>{{item?.title}}</mat-card-title>
                <mat-card-subtitle>{{item?.subtitle}}</mat-card-subtitle>
            </mat-card-header>

            @if(img){
            <img mat-card-image [src]="[item?.image | image | async]" [alt]="item?.title">
            }

            <mat-card-content>
                <!-- <form [formGroup]="quotaForm" (ngSubmit)="onSubmit()"> -->
                <form [formGroup]="quotaForm" class="flex-column">

                    <mat-form-field>
                        <mat-label>{{item.firstName}}</mat-label>
                        <input matInput type="text" formControlName="firstName" maxlength="50" required
                            [placeholder]="item.firstName">
                        <mat-hint align="end">{{quotaForm.get('firstName')!.value.length || 0}}/50
                        </mat-hint>
                        @if(quotaForm.get('firstName')!.hasError('required')){
                        <mat-error> {{item.firstName}} is required </mat-error>
                        }
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{item.lastName}}</mat-label>
                        <input matInput type="text" formControlName="lastName" maxlength="50" required
                            [placeholder]="item.lastName">
                        <mat-hint align="end">{{quotaForm.get('lastName')!.value?.length || 0}}/50
                        </mat-hint>
                        @if(quotaForm.get('lastName')!.hasError('required')){
                        <mat-error> {{item.lastName}} is required </mat-error>
                        }
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{item.email}}</mat-label>
                        <input matInput type="email" formControlName="email" maxlength="80" required
                            [placeholder]="item.email">
                        <mat-hint align="end">{{quotaForm.get('email')!.value?.length || 0}}/80
                        </mat-hint>
                        @if(quotaForm.get('email')!.hasError('required')){
                        <mat-error> {{item.email}} is required </mat-error>
                        }
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{item.phone}}</mat-label>
                        <input matInput type="tel" formControlName="phone" maxlength="20" required
                            [placeholder]="item.phone">
                        <mat-hint align="end">{{quotaForm.get('phone')!.value?.length || 0}}/20
                        </mat-hint>
                        @if(quotaForm.get('phone')!.hasError('required')){
                        <mat-error> {{item.phone}} is required </mat-error>
                        }
                    </mat-form-field>

                    <!-- <mat-radio-group  class="radio--group" required formControlName="type">
                                    @for( type of item?.type | keyvalue; track type){
                                    <mat-radio-button class="radio--button"
                                        [value]="type.key">
                                        {{type.value}}
                                    </mat-radio-button>
                                }
                                </mat-radio-group> -->

                    <mat-form-field>
                        <mat-label>{{item.question}}</mat-label>
                        <textarea matInput type="text" formControlName="question" rows=5 maxlength="200"
                            [placeholder]="item.question"></textarea>
                        <mat-hint align="end">{{quotaForm.get('question')!.value?.length || 0}}/200
                        </mat-hint>
                        @if(quotaForm.get('question')!.hasError('required')){
                        <mat-error> {{item.question}} is required </mat-error>
                        }
                    </mat-form-field>

                    <!-- <mat-form-field hintLabel="">
                                <textarea matInput type="text" formControlName="comment" rows=5 maxlength="200"
                                    [placeholder]="item.comment"></textarea>
                                <mat-hint align="end">{{quotaForm.get('comment')!.value?.length || 0}}/200
                                </mat-hint>
                            </mat-form-field> -->

                </form>
            </mat-card-content>

            <mat-card-actions>
                <button mat-button color="primary" [disabled]="!quotaForm.valid" (click)="addQuota()"
                    [routerLink]="['/', language, 'main']">
                    <!-- <mat-icon>send</mat-icon>&nbsp; -->
                    {{item.save }}
                </button>
                <button mat-button color="accent" [routerLink]="['/', language, 'main' ]">{{item.cancel }}</button>
            </mat-card-actions>

            <mat-card-footer>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>

        </mat-card>
        <!-- {{quotaForm.value | json}} -->
    </div>
</div>
}