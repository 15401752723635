import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@angular/fire/auth';
import { EMPTY, Observable } from 'rxjs';


@Component({
  selector: 'lib-md-login',
  templateUrl: './md-login.component.html',
  styleUrls: ['../style.scss']
})
export class MdLoginComponent {
  @Input() user: Observable<User | null> = EMPTY;
  @Input() showLogin: boolean = false;
  @Input() showLogout: boolean = false;


  // @Output() loginEvent = new EventEmitter<any>();
  // @Output() signupEvent = new EventEmitter<any>();
  @Output() loginGoogleEvent = new EventEmitter<void>();
  @Output() loginEMailEvent = new EventEmitter<void>();
  @Output() logoutEvent = new EventEmitter<void>();

  email: string = '';
  password: string = '';


  constructor() { }


  // signup(email: string, password: string) {
  //   this.signupEvent.emit({ email, password });
  // }

  // login(email: string, password: string) {
  //   this.loginEvent.emit({ email, password });
  // }

  loginEMail() {
    this.loginEMailEvent.emit();
    console.log('loginEMail');
  }

  loginGoogle() {
    this.loginGoogleEvent.emit();
    console.log('loginGoogle');
  }

  logout() {
    this.logoutEvent.emit();
    console.log('logout');
  }

}
