<!-- <p>### md-card works!</p> -->

@if(article$ | async; as article){

<div class="flex-title">
    <h2 class="mat-headline-large">{{article.title2}}</h2>
    <p>{{article.subtitle2}}</p>
</div>

@if(crd){
<article class="grid3">
    @for (card of article.card; track card.title) {
    <mat-card appearance="outlined">

        @if(img){
        <!-- <img mat-card-image [src]="['assets/image/' + card?.image]" [alt]="card?.title"> -->
        <img mat-card-image [src]="[card?.image | image | async]" [alt]="card?.title" style="height: 18rem;">
        }

        <mat-card-header>
            <mat-card-title>{{card.title}}</mat-card-title>
            <mat-card-subtitle>{{card.subtitle}}</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            @if (txt) {
            <div [innerHTML]="card.text" class="inner"></div>
            }
        </mat-card-content>

        <span style="flex: 1 1 auto;"></span>

        <mat-card-actions>
            @if (btn) {
            @if (txt) {
            <button mat-button (click)="txt=!txt">Wenig...</button>
            }
            @else {
            <button mat-button (click)="txt=!txt">Mehr...</button>
            }
            <!-- <button mat-button (click)="txt=!txt">{{card?.label1 }}</button> -->
            <!-- <button mat-button routerLink="['/', language, card.link1]">{{card?.label1 }}</button> -->
            <!-- <button mat-button routerLink="['/', language, card.link2]">{{card?.label2 }}</button> -->
            }
        </mat-card-actions>

    </mat-card>
    }
</article>
}
}