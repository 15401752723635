import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MdCartComponent } from './md-cart.component';

import { MdOrderModule } from '../md-order/md-order.module';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { PipeModule } from '../../pipe/pipe.module';

const modules = [
  CommonModule,
  RouterModule,
  PipeModule,
  MdOrderModule,
  MatButtonModule,
  MatCardModule,
  MatDividerModule,
  MatSnackBarModule,
  MatChipsModule,
  MatIconModule,
]
@NgModule({
  declarations: [
    MdCartComponent,
  ],
  imports: [
    ...modules
  ],
  exports: [
    MdCartComponent,
  ]
})
export class MdCartModule { }
