<!-- <p>### md-jobs works!</p> -->


@if(article$ | async; as article){

<div class="flex-title">
    <h2 class="mat-headline-large">{{article.title2}}</h2>
    <p>{{article.subtitle2}}</p>
</div>

@if(crd){
<article class="grid3">
    @for (card of article.card; track card) {
    <mat-card appearance="outlined" style="background-image: url(../assets/default.jpg);">

        @if(img){
        <img mat-card-image [src]="[card?.image | image | async]" [alt]="card?.title" style="height: 15rem;">
        }
        <!-- <img mat-card-image [src]="['assets/image/' + card?.image]" [alt]="card?.title"> -->

        <mat-card-header>
            <mat-card-title>{{card.title}}</mat-card-title>
            <mat-card-subtitle>{{card.subtitle}}</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            @if(card?.tag){
            <mat-chip-set #chipList aria-label="Tag set">
                @for(tag of card.tag?.split(','); track tag){
                <mat-chip color="primary"> {{tag}} </mat-chip>
                }
            </mat-chip-set>
            }
        </mat-card-content>

        <!-- @if(btn){ -->
        <mat-card-actions>
            @if(!txt) {
            <button mat-button color="primary" (click)="txt=!txt">{{card?.label1}}
            </button>
            } @else {
            <button mat-button color="primary" (click)="txt=!txt">{{card?.label2}}
            </button>
            <!-- <button mat-button [routerLink]="['/', language, 'page', 1]">{{card?.label1 }}</button> -->
            <!-- <button mat-button routerLink="['/', language, card.link1]">{{card?.label1}}</button> -->
            }
        </mat-card-actions>
        <!-- } -->

        <mat-card-content>
            @if(txt){
            <div [innerHTML]="card.text??'' | markdown"></div>
            }
        </mat-card-content>

    </mat-card>
    }
</article>
}

}