import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cat } from '../types';



@Component({
  selector: 'lib-md-order',
  templateUrl: './md-order.component.html',
  styleUrls: ['../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdOrderComponent implements OnInit {
  @Input() cartSubject$ = new BehaviorSubject<Cat[]>([]);

  totalSubject$ = new BehaviorSubject(0);

  // @Input() content$: Observable<any> = of();
  cart$: Observable<Cat[]> = of();
  total$: Observable<number> = of();


  ngOnInit() {
    this.cart$ = this.cartSubject$.asObservable();
    this.total();
    this.total$ = this.totalSubject$.asObservable();
  }

  total() {
    return this.cartSubject$.asObservable()
      .pipe(map(x => x.reduce((total, val) =>
        total + (val.qty * val.price), 0)))
      .subscribe((data => this.totalSubject$.next(data)));
  }

}
