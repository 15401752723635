import { NgModule } from '@angular/core';

import { CleanModule } from './clean/clean.module';
import { ImageModule } from './image/image.module';
import { MarkdownModule } from './markdown/markdown.module';
import { SplitModule } from './split/split.module';
import { HighlightModule } from './highlight/highlight.module';

import { CleanPipe } from './clean/clean.pipe';
import { ImagePipe } from './image/image.pipe';
import { MarkdownPipe } from './markdown/markdown.pipe';
import { SplitPipe } from './split/split.pipe';
import { HighlightPipe } from './highlight/highlight.pipe';

  @NgModule({
    imports: [
      CleanModule,
      ImageModule,
      MarkdownModule,
      SplitModule,
      HighlightModule,
    ],
    exports: [
      CleanPipe,
      ImagePipe,
      MarkdownPipe,
      SplitPipe,
      HighlightPipe,
    ]
  })
  export class PipeModule { }
