<!-- <p>### md-footer works!</p> -->


@if(list$ | async; as list;){
<footer class="flex-rc" style="justify-content: space-between;">

    <div style="flex: 1 1 auto; padding: 2rem 1rem;">
        <h2 class="mat-headline-large">{{list.info.company}}</h2>
        <p>{{list.info.city}} {{list.info.zip}}<br>{{list.info.address1}}</p>
        <a [href]="'tel:'+list.info.phone">{{list.info.phone}}</a>
    </div>

    <div style="flex: 1 1 auto;">
        <mat-nav-list role="list">
            <div mat-subheader>LINKS</div>
            @for (item of list.footer1; track item) {
            <mat-list-item role="listitem" [routerLink]="['/', language, item.link]">
                {{item.label}}
            </mat-list-item>
            }
        </mat-nav-list>
    </div>

    <div style="flex: 1 1 auto;">
        <mat-nav-list role="list">
            <div mat-subheader>LEGAL</div>
            @for (item of list.footer2; track item) {
            <mat-list-item role="listitem" [routerLink]="['/', language, 'page', item.link]">
                {{item.label}}
            </mat-list-item>
            }
        </mat-nav-list>
    </div>
</footer>

<div class="flex-title">
    <a mat-button href="https://jagst.design" target="_blank">{{list.info.copyright}}</a>
</div>
}