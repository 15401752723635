import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split',
  pure: true
})
export class SplitPipe implements PipeTransform {
  transform(value: string, delimiter: string = ','): string[] | null {
    if (value) {
      return value.replace(' ', '').split(delimiter);
      // return value.replace(' ', '').split(',');
    } else {
      return null;
    }
  }
}
