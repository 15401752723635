import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clean',
  pure: true

})
export class CleanPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/<.*?>/g, ''); // replace tags
  }

}
