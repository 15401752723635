import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { doc, docData, collection, collectionData, addDoc, Firestore, query, orderBy, where } from '@angular/fire/firestore';
// import { traceUntilFirst } from '@angular/fire/performance';
// import { keepUnstableUntilFirst } from '@angular/fire';

import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';

import { Quota } from '../types';
import { traceUntilFirst } from '@angular/fire/performance';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  
  // jobs$: Observable<Card> = of();
  aboutSubject$ = new BehaviorSubject<any>('');
  calSubject$ = new BehaviorSubject<any>('');
  contactSubject$ = new BehaviorSubject<any>('');
  jobsSubject$ = new BehaviorSubject<any>('');
  mainSubject$ = new BehaviorSubject<any>('');
  mapsSubject$ = new BehaviorSubject<any>('');
  photoSubject$ = new BehaviorSubject<any>('');
  teamSubject$ = new BehaviorSubject<any>('');
  videoSubject$ = new BehaviorSubject<any>('');
  workSubject$ = new BehaviorSubject<any>('');
  
  callSubject$ = new BehaviorSubject<any>('');

  constructor(
    private firestore: Firestore,
    private formBuilder: FormBuilder,
  ) { }


  // Articles from Firestore

  fsArticles<T>(path: string,
    aliasFilter$: BehaviorSubject<string>,
    languageFilter$: BehaviorSubject<string>,
    countryFilter$: BehaviorSubject<string>): Observable<T[]> {
    return combineLatest(
      [aliasFilter$, languageFilter$, countryFilter$]
    ).pipe(
      switchMap(([alias, language, country]) => {

        const constraints = [];
        if (alias) constraints.push(where('alias', '==', alias));
        if (language) constraints.push(where('language', '==', language));
        if (country) constraints.push(where('country', '==', country));

        return collectionData(
          query(collection(this.firestore, path), ...constraints),
          { idField: 'id' }) as Observable<T[]>;
      })
    );
  }

  fsArticle<T>(l: string, p: string): Observable<T> {

    const path: string = '/';

    const pathSegments = [];
    if (l) pathSegments.push(l);
    if (p) pathSegments.push(p);

    // const constraints = [];
    // if (c) constraints.push(where('c', '==', c));
    // constraints.push(limit(10));

    return docData(doc(this.firestore, path, ...pathSegments), { idField: 'id' })
      .pipe(traceUntilFirst('firestore')) as Observable<T>;
  }

  bsInit (language: string) {

    this.fsArticle(language, 'about').subscribe(
      x => { this.aboutSubject$.next(x); }
    );
    this.fsArticle(language, 'cal').subscribe(
      x => { this.calSubject$.next(x); }
    );
    this.fsArticle(language, 'contact').subscribe(
      x => { this.contactSubject$.next(x); }
    );
    this.fsArticle(language, 'jobs').subscribe(
      x => { this.jobsSubject$.next(x); }
    );
    this.fsArticle(language, 'main').subscribe(
      x => { this.mainSubject$.next(x); }
    );
    this.fsArticle(language, 'maps').subscribe(
      x => { this.mapsSubject$.next(x); }
    );
    this.fsArticle(language, 'photo').subscribe(
      x => { this.photoSubject$.next(x); }
    );
    this.fsArticle(language, 'team').subscribe(
      x => { this.teamSubject$.next(x); }
    );
    this.fsArticle(language, 'video').subscribe(
      x => { this.videoSubject$.next(x); }
    );
    this.fsArticle(language, 'work').subscribe(
      x => { this.workSubject$.next(x); }
    );

    this.fsArticle(language, 'call').subscribe(
      x => { this.callSubject$.next(x); }
    );

    // this.seoService.seoRobots(this.article$);


    // this.jobs$.subscribe(
    //   x => { this.jobsSubject$.next(x); }
    // );

    // this.jobsSubject$.asObservable().subscribe(x => { console.log('CS jobsBS-', x) });
  }

  // Quota

  orderNo(min: number, max: number): number {
    // https://www.w3schools.com/js/js_random.asp
    return Math.floor(Math.random() * (max - min)) + min;
  }

  buildQuota(): FormGroup {
    return this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z ]*')]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z ]*')]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(4)]],
      question: ['', [Validators.required, Validators.minLength(10)]],
      comment: [''],
    });
  }

  getQuotas(): Observable<Quota[]> {
    const collectionRef = query(
      collection(this.firestore, 'quota'),
      orderBy('updAt', 'desc'));

    return collectionData(collectionRef, { idField: 'id' }) as Observable<Quota[]>;
  }

  async addQuota(path: string, quota: Quota) {
    const collectionRef = collection(this.firestore, path);
    // quota.updAt = firebase.Timestamp.now();

    const docRef = await addDoc(collectionRef, quota);
    console.log("Document written with ID: ", docRef.id);

    // this.snackBar.open('Quota sent', 'OK', { duration: 5000 });
    // const quotaId = this.firestore.createId();
    // quota.order = [{ title: 'test', price: 100, qty: 2 }] // test
  }


}
