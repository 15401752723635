import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MdPhotoComponent, MdPhotoDialogComponent } from './md-photo.component';

import { PipeModule } from '../../pipe/pipe.module';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

const modules = [
  CommonModule,
  PipeModule,
  MatGridListModule,
  MatDialogModule,
  MatButtonModule,
]

const components = [
  MdPhotoComponent,
  MdPhotoDialogComponent,
]
@NgModule({
  declarations: [
    ...components
  ],
  imports: [
...modules
  ],
  exports: [
    ...components
  ]
})
export class MdPhotoModule { }
