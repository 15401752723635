import { NgModule } from '@angular/core';

import { MdcBoModule } from './mdc-bo/mdc-bo.module';
import { MdcFoModule } from './mdc-fo/mdc-fo.module';
import { PipeModule } from './pipe/pipe.module';


@NgModule({
  declarations: [
  ],
  imports: [
    MdcBoModule,
    MdcFoModule,
    PipeModule
  ],
  exports: [
    MdcBoModule,
    MdcFoModule,
    PipeModule
  ]
})
export class MdcLibModule { }
