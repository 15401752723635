<!-- <p>### md-hero works!</p> -->

@if(article$ | async; as article;){
<div class="container_photo">

    <img [ngSrc]=imagePath fill priority [alt]="article?.title">
    <!-- <img [ngSrc]="[article?.image | image | async]" fill [alt]="article?.title"> -->
    <!-- <img [ngSrc]="isVisible ? 'path-to-image.jpg' : null" (isVisible)="isVisible = true"> -->

    <div class="container_text">

        <!-- @defer (on timer(1s)) { -->
        <h1 class="mat-display-large">{{article?.title}}</h1>
        <p class="mat-display-small">{{article?.subtitle}}</p>
        <!-- } -->

        @if(btn){
        <div style="display: flex; flex-flow: row wrap; gap: .5rem;">
            @if(article?.link1){
            <button mat-flat-button color="primary" [routerLink]="['/', language, article.link1]">
                <mat-icon>arrow_forward</mat-icon>
                <span>{{ article?.label1 }}</span>
            </button>
            }
            @if(article?.link2){
            <button mat-flat-button color="accent" [routerLink]="[ '/', language, article?.link2 ]">
                <mat-icon>arrow_forward</mat-icon>
                <span>{{ article?.label2 }}</span>
            </button>
            }
        </div>
        }

    </div>
</div>
}