<!-- <p>### md-cart works!</p> -->

<!-- {{(cat$ | async)?.data[0].title | json}} -->

<!-- <p>### md-price works!</p> -->

<div class="flex-title">
    <h2 class="mat-headline-large">Cart solution</h2>
    <p>Whether you’re selling a single product, you need full aisle or display merchandising, or you’re looking at
        company-wide merchandising, we’re here to help. We scale to your needs.</p>
</div>

<!-- https://stackoverflow.com/questions/62995244/angular-material-chips-activated-chip-ngclass -->
@if(filter$ | async; as filter){
<mat-chip-listbox>
    @for (chip of filter; track chip) {
    <mat-chip-option (click)="filterGroup(chip?.key)">
        <!-- <img matChipAvatar src="assets/events-background.jpg" alt="..."> -->
        {{chip.value}}
    </mat-chip-option>
    }
</mat-chip-listbox>
}

@if(filterSubject$ | async; as items){

<article class="grid3">
    @for (item of items; track item; let i = $index) {
    <mat-card appearance="outlined">

        <img [src]="item?.image | image | async" [alt]="item?.title"
            style="height: 16rem; aspect-ratio: 1/1; background-color: darkslategrey;">

        <mat-card-header>
            <mat-card-subtitle>{{item?.overtitle | uppercase}} </mat-card-subtitle>
            <mat-card-title>{{item?.title}}</mat-card-title>
            <mat-card-subtitle>{{item?.subtitle}}</mat-card-subtitle>
            <!-- <mat-card-title align="end">{{item?.price | currency : item?.currency : 'symbol' : '1.2-2'}} </mat-card-title> -->
            <small>#{{item.id}}</small>
        </mat-card-header>

        @if(btn){
        <mat-card-actions>
            @if(ci===i){
            <!-- <a mat-button color="primary" (click)="showText(-1)"> Less </a> -->
            }@else{
            <!-- <a mat-button color="primary" (click)="showText(i)"> More </a> -->
            }
        </mat-card-actions>
        }

        <span style="flex: 1 1 auto;"></span>

        <mat-card-content>
            @if(ci===i){
            <div [innerHTML]=[item?.text] style="hyphens: auto;"></div>
            }
            <!-- <div class="text-sm text-gray-400" *ngFor="let p of (item?.discount | keyvalue)">
                x{{p.key}} {{p.value}}
            </div> -->
            <mat-card-title>{{item?.price | currency : item?.currency : 'symbol' : '1.2-2'}} </mat-card-title>
            <p>UVP {{item?.uvp | currency : item?.currency : 'symbol' : '1.2-2'}}</p>
        </mat-card-content>

        @if(btn){
        <mat-card-actions style="display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 1rem;">

            <!-- @if(item?.qty <= item?.mn){  -->
            <button mat-mini-fab (click)="decr(item.id)" style="justify-self: end;">
                <mat-icon>remove</mat-icon>
            </button>

            <h2 style="justify-self: center;"> {{item?.qty}} </h2>

            <!-- @if(item?.qty < item?.mx){ -->
            <button mat-mini-fab (click)="incr(item.id)" style="justify-self: start;">
                <mat-icon>add</mat-icon>
            </button>
        </mat-card-actions>
        }@else{
        <mat-card-actions>
            <button mat-button (click)="snackBar('Buy','undo')" color="primary">{{ item.label1 }}</button>
            <button mat-button (click)="snackBar('Like','undo')" color="accent">{{ item.label2 }}</button>
        </mat-card-actions>
        }

    </mat-card>
    }

</article>

@if(btn){
<lib-md-order [cartSubject$]=cartSubject$></lib-md-order>
}

}