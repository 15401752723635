<!-- <p>### md-cal works!</p> -->

@if(article$ | async; as article){
<div class="flex-column">

    <div class="flex-title">
        <h2 class="mat-headline-large">{{article.title2}}</h2>
        <p>{{article.subtitle2}}</p>
    </div>

    @if(crd){

    <article class="grid3">
        @for (card of article.card; track card) {
        <mat-card appearance="outlined" class="flex-row">

            <div class="flex-brick-left">
                @if(img) {
                <img mat-card-sm-image [src]="[card?.image | image | async]" [alt]="card?.title">
                }
            </div>

            <div class="flex-brick-right">
                <mat-card-header>
                    <mat-card-title>{{card?.title}}</mat-card-title>
                    <mat-card-subtitle>{{card?.dateStart.toDate() | date:'mediumDate' }}
                        &nbsp; {{card?.town}}
                    </mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <div [innerHTML]="card.text" class="inner"></div>

                    @if(card.tag){
                    <mat-chip-listbox #chipList aria-label="Tag selection">
                        @for(tag of card.tag.split(','); track tag){
                        <mat-chip-option color="primary"> {{tag}} </mat-chip-option>
                        }
                    </mat-chip-listbox>
                    }
                </mat-card-content>

                <span style="flex: 1 1 auto;"></span>

                @if(btn){
                <mat-card-actions>
                    <!-- <mat-divider></mat-divider> -->
                    <a mat-button color="primary" [href]=card.link1 target="_blank" aria-label="Link">{{card?.label1}}</a>
                    <!-- <button mat-button routerLink="['/', language, card.link2]">{{card?.label2}}</button> -->
                </mat-card-actions>
                }
            </div>


        </mat-card>
        }
    </article>

    }
</div>
}