<!-- <p>### md-login works!</p> -->

<div class="grid3">
    <mat-card appearance="outlined">

        @if(showLogout) {
        @if(user | async; as user){
        <mat-card-header>
            <img mat-card-avatar [src]="user?.photoURL" [alt]="user?.email">
            <mat-card-title>Hello, {{ user.displayName }}</mat-card-title>
            <mat-card-subtitle>{{ user?.email }}</mat-card-subtitle>
        </mat-card-header>
        }

        <mat-card-actions>
            <button mat-button color="primary" routerLink="/quota" aria-label="Quota">Editor</button>
            <button mat-button color="accent" (click)="logout()" aria-label="Logout">Logout</button>
        </mat-card-actions>
        }

        @if(showLogin) {
        <mat-card-header>
            <mat-card-title>Please Login.</mat-card-title>
        </mat-card-header>

        <mat-card-actions>
            <button mat-button color="primary" (click)="loginGoogle()" aria-label="Login">Login with Google</button>
        </mat-card-actions>
        }
    </mat-card>
</div>

<!-- {{(user | async)?.email}} -->