import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { Quota } from '../../mdc-fo/types';


@Component({
  selector: 'lib-md-quota',
  templateUrl: './md-quota.component.html',
  styleUrls: ['../style.scss']
})
export class MdQuotaComponent {
  @Input() quota$: Observable<Quota[]> = new Observable();

  constructor() { }



}
