import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { Article } from '../types';

@Component({
  selector: 'lib-md-page',
  templateUrl: './md-page.component.html',
  styleUrls: ['../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdPageComponent {
  @Input() mdx$: Observable<Article> = new Observable();

  constructor() { }


}
