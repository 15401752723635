import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MdBrickComponent } from './md-brick.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { PipeModule } from '../../pipe/pipe.module';

const modules = [
  CommonModule,
  RouterModule,
  // NgOptimizedImage,
  PipeModule,
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
]
@NgModule({
  declarations: [
    MdBrickComponent,
  ],
  imports: [
    ...modules
  ],
  exports: [
    MdBrickComponent,
  ]
})
export class MdBrickModule { }
