import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MdJobsComponent } from './md-jobs.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { PipeModule } from '../../pipe/pipe.module';

const modules = [
  CommonModule,
  RouterModule,
  PipeModule,
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDividerModule,
]
@NgModule({
  declarations: [
    MdJobsComponent,
  ],
  imports: [
    ...modules
  ],
  exports: [
    MdJobsComponent,
  ]
})
export class MdJobsModule { }
