import { Pipe, PipeTransform } from '@angular/core';
import { marked } from "marked";

@Pipe({
  name: 'markdown',
  pure: true
})

export class MarkdownPipe implements PipeTransform {

  transform(value: string): string | Promise<string> {
    if (value && value.length > 0) {
      return marked(value);
    }
    return value;
  }

}