<!-- <p>### md-order works!</p> -->

@if(cart$ | async; as cart){

<!-- {{cart | json}} -->

<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>
            Order Total: {{total$ | async | currency : 'EUR' : 'symbol' : '1.2-2'}}
        </mat-card-title>
    </mat-card-header>

    <mat-list style="justify-self: stretch;">
        @for (item of cart; track item.id) {
        @if((item?.qty??0)>0){
        <mat-list-item>
            <span matListItemTitle>#{{item.id}} {{item?.title}}</span>
            <span matListItemLine>{{item?.qty}} x
                {{item.price | currency : item?.currency : 'symbol' : '1.2-2'}}
                =
                {{ item.qty * item.price | currency : item?.currency : 'symbol' : '1.2-2'}}
            </span>
            <span matListItemLine>&nbsp; {{item?.available}}</span>
            <!-- <img mat-card-image [src]="[item?.image | image | async]" [alt]="item?.title"> -->
        </mat-list-item>
        } }
    </mat-list>

</mat-card>
}