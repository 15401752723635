<!-- <p>### md-navi works!</p> -->

<mat-sidenav-container class="sidenav-container">

  <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'">
    <!-- [opened]="!(isHandset$ | async)"> -->

    @if(list$ | async; as list){
    <mat-nav-list role="list">
      <div mat-subheader>
        <mat-icon (click)="drawer.close()">close</mat-icon>
      </div>

      @for (item of list.navigation; track item) {
      <mat-list-item role="listitem" [routerLink]="['/', language, item.link]">
        {{item.label}}
      </mat-list-item>
      }
    </mat-nav-list>
    }
  </mat-sidenav>

  <mat-sidenav-content>

    <header>
      <mat-toolbar>
        <button mat-icon-button color="primary" aria-label="Toggle sidenav" (click)="drawer.toggle()">
          <!-- *ngIf="isHandset$ | async"> -->
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>

        <a [routerLink]="[ '/', language, 'main' ]">
          <div style="font-size: 120%;">{{logo}}</div>
        </a>

        <span style="flex: 1 1 auto;"></span>

        <span> {{language}} </span>

        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>language</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          @for(item of (menu$ | async)?.lang; track item){
          <!-- <button mat-menu-item [routerLink]="[ '/', language, path ]" (click)="emitLanguage(item.lang)" aria-label="Language"> -->
          <button mat-menu-item [routerLink]=item.link (click)="emitLanguage(item.lang)" aria-label="Language">
            <span>{{item.label}}</span>
          </button>
          }
        </mat-menu>

      </mat-toolbar>
    </header>

    <!-- Add Content Here -->

    <main>
      <router-outlet></router-outlet>
      <lib-md-footer [list$]="list$" [language]="language"></lib-md-footer>

      <!-- <div [@routeAnimations]="getRouteAnimationData()">
        <router-outlet style="min-height: 100vh;"></router-outlet>
        <lib-md-footer [list$]="list$" [language]="language"></lib-md-footer>
      </div> -->
    </main>

  </mat-sidenav-content>

</mat-sidenav-container>