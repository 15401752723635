import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MdHeroComponent } from './md-hero.component';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PipeModule } from '../../pipe/pipe.module';

const modules = [
  CommonModule,
  RouterModule,
  NgOptimizedImage,
  PipeModule,
  MatButtonModule,
  MatIconModule,
]

@NgModule({
  declarations: [
    MdHeroComponent,
  ],
  imports: [
    ...modules
  ],
  exports: [
    MdHeroComponent,
  ]
})
export class MdHeroModule { }
