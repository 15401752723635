import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


import { Cat, Catalog } from '../types';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'lib-md-cart',
  templateUrl: './md-cart.component.html',
  styleUrls: ['../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdCartComponent implements OnInit {
  @Input() language: string = 'de';
  @Input() catalog$: Observable<Catalog> = of();

  @Input() btn: boolean = true;
  @Input() img: boolean = true;
  @Input() crd: boolean = true;
  @Input() txt: boolean = true;

  cartSubject$ = new BehaviorSubject<Cat[]>([]);
  filterSubject$ = new BehaviorSubject<Cat[]>([]);

  filter$: Observable<any[]> = of([]);

  ci = -1;
  i = 0;

  constructor(
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.clearCart();
    this.filterGroup(1);
    this.filter$ = this.catalog$.pipe(map(x => x.groups))
  }

  incr(id: number) {
    const itemForUpate = this.cartSubject$.getValue();
    // console.log('ds=', itemForUpate);

    itemForUpate.map((item) => {
      // console.log('item=',id, item.id, item.qty, item.mx)

      // if (item.qty) {
        if (item.id === id && item.qty < item.mx) {
          // console.log('q+',item.qty)
          return { ...item, qty: item.qty++ };
        }
        return item;
      // } else {
      //   return 0;
      // }
    });

    this.cartSubject$.next(itemForUpate);
  }

  decr(id: number) {
    const itemForUpate = this.cartSubject$.getValue();

    itemForUpate.map((item) => {
      // if (item.qty) {
      if (item.id === id && item.qty > 0) {
        console.log('q-', item.qty)
        return { ...item, qty: item.qty-- };
      }
      return item;
      // } else {
      //   return 0;
      // }
    });

    this.cartSubject$.next(itemForUpate);
  }

  showText(i: number) {
    this.ci = i;
  }

  discount(item: any) {
    const min = Math.min(...item.discount.values());
    console.log(min);
    return min;
  }

  filterGroup(id: number) {
    this.cartSubject$.asObservable()
      .pipe(map((x) => x.filter((val) => val.group == id || id == 0)))
      .subscribe((data => this.filterSubject$.next(data)));
  }

  clearCart() {
    this.catalog$.subscribe(
      x => {
        const cat = x.data;
        this.cartSubject$.next(cat);
      }
    );
  }

  snackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 1000, });
  }

}
