<!-- <p>### md-brick works!</p> -->

@if(article$ | async; as article){
<div class="flex-column">

    <div class="flex-title">
        <h2 class="mat-headline-large">{{article.title2}}</h2>
        <p>{{article.subtitle2}}</p>
    </div>

    @if(crd){
    <article class="flex-column">
        @for (card of article.card; track card) {
        <mat-card appearance="outlined" class="flex-row">

            <div class="flex-brick-left" style="border-radius: 8px 0px 0px 8px;">
                <img mat-card-image [src]="[card?.image | image | async]" [alt]="card?.title">
            </div>

            <div class="flex-brick-right">
                <mat-card-header>
                    <!-- <img mat-card-avatar [src]="[card?.image | image | async]" [alt]="card?.title"> -->
                    <mat-card-title>{{card.title}}</mat-card-title>
                    <mat-card-subtitle>{{card.subtitle}}</mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <div [innerHTML]="card.text" class="inner"></div>

                    @if(card.tag){
                    <mat-chip-listbox #chipList aria-label="Tag selection">
                        @for(tag of card.tag.split(','); track tag){
                        <mat-chip-option color="primary"> {{tag}} </mat-chip-option>
                        }
                    </mat-chip-listbox>
                    }

                    <!-- <span style="flex: 1 1 auto;"></span> -->
                </mat-card-content>

                @if(btn){
                <mat-card-actions>
                    <button mat-button [routerLink]="['/', language, 'page', card.link1]"
                        aria-label="Page">{{card?.label1 }}</button>
                    <!-- <button mat-button routerLink="['/', language, card.link1]">{{card?.label1 }}</button> -->
                    <!-- <button mat-button routerLink="['/', language, card.link2]">{{card?.label2 }}</button> -->
                </mat-card-actions>
                }

            </div>

        </mat-card>
        }
    </article>
    }
</div>
}