<!-- <p>### md-photo works!</p> -->

@if(article$ | async; as article){
<div class="flex-column">

    <div class="flex-title">
        <h2 class="mat-headline-large">{{article.title2}}</h2>
        <p>{{article.subtitle2}}</p>
    </div>

    <mat-grid-list [cols]="columns" rowHeight="3:2" gutterSize="0.5rem" (window:resize)="onResize($event)">
        @for (card of article.card; track card) {
        <mat-grid-tile>
            <img [src]="[card?.image | image | async]" [alt]="card?.title" class="cover">
            @if (hdr) {
            <mat-grid-tile-header>
                {{card?.title}}
                {{card?.subtitle}}
            </mat-grid-tile-header>
            }
            @if (ftr) {
            <mat-grid-tile-footer>
                <button mat-button (click)="openDialog('300ms', '300ms', card)">Delete</button>
            </mat-grid-tile-footer>
            }
        </mat-grid-tile>
        }
    </mat-grid-list>

</div>
}