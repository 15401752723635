import { ChangeDetectionStrategy, Component, Input, OnInit, Output, inject } from '@angular/core';
import { addDoc, collection, collectionData, Firestore, orderBy, query } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

import { Observable, of } from 'rxjs';

import { Contact, Quota } from '../types';


@Component({
  selector: 'lib-md-form',
  templateUrl: './md-form.component.html',
  styleUrls: ['../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdFormComponent implements OnInit {
  @Input() language: string = 'de';
  @Input() contact$: Observable<Contact> = of();
  @Input() quotaForm: FormGroup;
  @Input() img: boolean = false;

  @Output() quotaEvent: string = '';

  firestore: Firestore = inject(Firestore);

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    // private firestore: Firestore,
    private snackBar: MatSnackBar
  ) {
    this.quotaForm = formBuilder.group({});
  }

  ngOnInit(): void {
    this.language = this.route.snapshot.data['language'];
    // this.contact$ = this.cs.getContact(this.url, this.language);
    this.quotaForm = this.buildQuota();
  }

  addQuota() {
    this.addQuota1('quota', this.quotaForm.value);
  }

  // Quota

  orderNo(min: number, max: number): number {
    // https://www.w3schools.com/js/js_random.asp
    return Math.floor(Math.random() * (max - min)) + min;
  }

  buildQuota(): FormGroup {
    return this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z ]*')]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z ]*')]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(4)]],
      question: ['', [Validators.required, Validators.minLength(10)]],
      comment: [''],
    });
  }

  getQuotas(): Observable<Quota[]> {
    const collectionRef = query(
      collection(this.firestore, 'quota'),
      orderBy('updAt', 'desc'));

    return collectionData(collectionRef, { idField: 'id' }) as Observable<Quota[]>;
  }

  async addQuota1(path: string, quota: Quota) {
    const collectionRef = collection(this.firestore, path);
    // quota.updAt = firebase.Timestamp.now();

    const docRef = await addDoc(collectionRef, quota);
    console.log("Document written with ID: ", docRef.id);

    this.snackBar.open('Quota sent', 'OK', { duration: 5000 });
    // const quotaId = this.firestore.createId();
    // quota.order = [{ title: 'test', price: 100, qty: 2 }] // test
  }


}
