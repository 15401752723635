import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { MdNaviComponent } from './md-navi.component';
import { MdFooterModule } from '../md-footer/md-footer.module';

const modules = [
  CommonModule,
  RouterModule,
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MdFooterModule,
]
@NgModule({
  declarations: [
    MdNaviComponent,
  ],
  imports: [
    ...modules
  ],
  exports: [
    MdNaviComponent,
  ]
})
export class MdNaviModule { }
