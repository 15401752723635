import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Article } from '../types';

@Component({
  selector: 'lib-md-hero',
  templateUrl: './md-hero.component.html',
  styleUrls: ['./md-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdHeroComponent {
  @Input() article$: Observable<Article> = of();
  @Input() language: string = 'de';
  @Input() btn: boolean = true;
  @Input() imagePath: string = '/assets/default.jpg';

  constructor() { }

}
