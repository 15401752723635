import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ActivatedRoute, ChildrenOutletContexts } from '@angular/router';
import { transition, trigger, useAnimation } from '@angular/animations';

import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { MenuItems, Navigation } from '../types';
import { fromLeftEasing } from './anima/router-animations';



@Component({
  selector: 'lib-md-navi',
  templateUrl: './md-navi.component.html',
  styleUrl: './md-navi.component.scss',
  animations: [
    trigger('routeAnimations', [
      transition('* => *', useAnimation(fromLeftEasing)),
      // transition('* => *', useAnimation(moveFromLeftFade)),
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,


})
export class MdNaviComponent {
  @Input() menu$: Observable<MenuItems> = of();
  @Input() list$: Observable<Navigation> = of();

  @Output() langEvent = new EventEmitter<string>();

  language: string = 'de';
  logo: string = 'Jagst Design';
  path: string = '';

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  private route = inject(ActivatedRoute);

  constructor(
    private breakpointObserver: BreakpointObserver,
    private contexts: ChildrenOutletContexts,
  ) { }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['state'];
  }

  emitLanguage(language: string) {
    this.langEvent.emit(language);
    this.language = language;
    // console.log('md-navi', language);
    this.route.parent?.url
      .subscribe(url => {
        this.path = url[0].path;
        console.log('url1-', this.path)
      });
    // this.route.parent?.url.subscribe(url => console.log('url2-',url));
    // this.route.url.subscribe(url => console.log(url));
    // this.route.data.subscribe(data => console.log('data',data));
    // this.route.url.subscribe(url => console.log('url3-',url));
  }


}
