import { NgModule } from '@angular/core';

import { SplitPipe } from './split.pipe';


@NgModule({
  declarations: [
    SplitPipe,
  ],
  imports: [
  ],
  exports: [
    SplitPipe,
  ]
})
export class SplitModule { }
