import { NgModule } from '@angular/core';

import { CleanPipe } from './clean.pipe';


@NgModule({
  declarations: [
    CleanPipe,
  ],
  imports: [
  ],
  exports: [
    CleanPipe,
  ]
})
export class CleanModule { }
