import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Article } from '../types';


@Component({
  selector: 'lib-md-card',
  templateUrl: './md-card.component.html',
  styleUrls: ['../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdCardComponent {
  @Input() article$: Observable<Article> = of();
  @Input() language: string = 'de';

  @Input() btn: boolean = false;
  @Input() img: boolean = true;
  @Input() crd: boolean = true;
  @Input() txt: boolean = false;

  constructor() { }

}
