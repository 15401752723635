<!-- <p>md-light works!</p> -->

<!-- {{files$ | async | json}} -->

<div class="grid3">

    @for (file of files; track $index) {
    <mat-card>
        <img mat-card-image [src]="file | image | async" [alt]="file">

        <mat-card-content>
        </mat-card-content>

        <span style="flex: 1 1 auto;"></span>

        <mat-card-actions>
            <button mat-button 
            [cdkCopyToClipboard]="file" 
            matTooltip="Copy path in Clipboard" 
            (onclick)="openSnackBar('info','action')"
            aria-label="File">
            {{file}}
            </button>
            <!-- <button mat-button
            (onclick)="openSnackBar()">{{2}}
            </button> -->
        </mat-card-actions>

    </mat-card>
    }

</div>
<!-- <img [src]="files$ | async" alt="test"/> -->
<!-- (click)="openSnackBar('Copied', 'Cancel')" -->