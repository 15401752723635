import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';


@Component({
  selector: 'lib-md-maps',
  templateUrl: './md-maps.component.html',
  styleUrls: ['../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdMapsComponent {

  options: google.maps.MapOptions = {
    center: { lat: 40, lng: -20 },
    zoom: 4,
    mapId: 'DEMO_MAP_ID',
  };

  markerOptions: google.maps.MarkerOptions = {
    draggable: false
  }

  markerPositions: google.maps.LatLngLiteral[] = [
    { lat: 41, lng: -20 },
    { lat: 42, lng: -20 }
  ]

  // center: google.maps.LatLngLiteral = { lat: 40, lng: -20 };
  // zoom = 4;


  constructor(
    geocoder: MapGeocoder
  ) {
    geocoder.geocode({
      address: '1600 Amphitheatre Parkway, Mountain View, CA'
    }).subscribe(({ results }) => {
      console.log(results);
    });
  }

  addMarker(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.markerPositions.push(event.latLng.toJSON());
    }
  }

}