import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Article } from '../types';

export interface DialogData {
  title: string;
  subtitle: string;
}


@Component({
  selector: 'lib-md-photo',
  templateUrl: './md-photo.component.html',
  styleUrls: ['../style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdPhotoComponent implements OnInit {
  @Input() article$: Observable<Article> = of();
  @Input() language: string = 'de';
  @Input() hdr: boolean = true;
  @Input() ftr: boolean = true;
  @Input() columns: number = 1;


  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.breakPoints();
  }

  breakPoints() {
    switch (true) {
      case (window.innerWidth <= 640):
        this.columns = 1;
        break;
      case (window.innerWidth <= 1280):
        this.columns = 2;
        break;
      case (window.innerWidth <= 1920):
        this.columns = 3;
        break;
      default:
        this.columns = 4;
    }
  }

  onResize(event: any) {
    this.breakPoints();
    console.log(event)
  }

  openDialog(enterAnimationDuration: string,
    exitAnimationDuration: string,
    card: DialogData): void {
    this.dialog.open(MdPhotoDialogComponent, {
      width: '20rem',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { title: card.title, subtitle: card.subtitle }
    });
  }

}


@Component({
  selector: 'lib-photo-dialog',
  templateUrl: 'md-photo-dialog.component.html',
})
export class MdPhotoDialogComponent {


  constructor(
    public dialogRef: MatDialogRef<MdPhotoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}