import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MdPageComponent } from './md-page.component';
import { PipeModule } from '../../pipe/pipe.module';

const modules = [
  CommonModule,
  PipeModule,
]
@NgModule({
  declarations: [
    MdPageComponent,
  ],
  imports: [
    ...modules
  ],
  exports: [
    MdPageComponent,
  ]
})
export class MdPageModule { }
