import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MdCardComponent } from './md-card.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { PipeModule } from '../../pipe/pipe.module';

const modules = [
  CommonModule,
  RouterModule,
  // NgOptimizedImage,
  PipeModule,
  MatButtonModule,
  MatCardModule,
]
@NgModule({
  declarations: [
    MdCardComponent,
  ],
  imports: [
    ...modules
  ],
  exports: [
    MdCardComponent,
  ]
})
export class MdCardModule { }
