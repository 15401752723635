import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MdLoginModule } from './md-login/md-login.module';
import { MdQuotaModule } from './md-quota/md-quota.module';
import { MdLightModule } from './md-light/md-light.module';
import { MdEditModule } from './md-edit/md-edit.module';

import { MdLoginComponent } from './md-login/md-login.component';
import { MdQuotaComponent } from './md-quota/md-quota.component';
import { MdLightComponent } from './md-light/md-light.component';
import { MdEditComponent } from './md-edit/md-edit.component';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    MdEditModule,
    MdLightModule,
    MdLoginModule,
    MdQuotaModule,
  ],
  exports: [
    MdEditComponent,
    MdLightComponent,
    MdLoginComponent,
    MdQuotaComponent,
  ]
})
export class MdcBoModule { }
