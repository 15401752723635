import { NgModule } from '@angular/core';

import { HighlightPipe } from './highlight.pipe';


@NgModule({
  declarations: [
    HighlightPipe,
  ],
  imports: [
  ],
  exports: [
    HighlightPipe,
  ]
})
export class HighlightModule { }
